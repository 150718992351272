<template>
  <div class="study">
    <Top></Top>
    <Fix></Fix>
    <div class="study-box">
      <!-- <img src="/static/images/study.png" alt=""> -->
      <img :src="require('/static/images/gaoxin.png')" alt="">
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Top from '../ui/top.vue';
import Foot from '../ui/foot.vue';
import Fix from '../ui/fix.vue';
export default {
  name: 'study',
  components: {
    Top,
    Foot,
    Fix
  }
}

</script>

<style scoped>
     .study-box{
    	 display: flex;
     	 justify-content: center;
       align-items: center;
       width: 100%;
       height: 100%;
       /* margin-bottom: 80px; */
      }
   .study-box>img {
    	width:auto;
        height:auto;
        max-width:100%;
        max-height:100%;
		}
</style>
